
import { defineComponent, computed, useRoute, useContext, ref, watch, onMounted } from '@nuxtjs/composition-api'
import Geolocation from '~/components/UI/Geolocation.vue'
import { getHeaderTopMenu } from '~/shared/const'
import { isUnknownObject } from '~/shared/utils/guards'
import type { MenuItem } from '~/interfaces/Header'

export default defineComponent({
  name: 'TopMenu',
  components: {
    Geolocation
  },
  setup() {
    const $route = useRoute()
    const context = useContext()

    const menuItems = ref<Array<MenuItem>>([])

    const checkHttps = (str: string) => {
      return str.includes('https')
    }

    const getMenuItems = () => {
      const region = context.$services.geolocation.getCookieRegion()
      menuItems.value = getHeaderTopMenu(isUnknownObject(region) && typeof region.slug === 'string' ? region.slug : '')
    }

    onMounted(() => getMenuItems())

    watch(() => context.$tstore.regionLocation.RegionLocationItem, getMenuItems, { immediate: true, deep: true })

    const isShowGeolocation = computed(() => {
      return $route.value.name === 'looking-for-job-slug' || $route.value.name?.includes('vacancies')
    })

    return {
      menuItems,
      isShowGeolocation,
      checkHttps
    }
  }
})
