import { Form } from '~/shared/api'
import {
  checkedRule,
  emailRule,
  emptyRule,
  minLengthCountRule,
  phoneRule,
  requiredRule
} from '~/shared/utils/validation'
import { DadataAddressModel } from '~/model/Dadata'

export default class WomenAPKForm extends Form {
  readonly contest = 'womans_apk'
  name: string = ''
  address: DadataAddressModel | null = null
  dateOfBirth: string = ''
  phone: string = ''
  email: string = ''
  education: string = ''
  placeOfWork: string = ''
  text: string = ''
  isAgreement: boolean = false

  toInputData() {
    return {
      contest: this.contest,
      data: {
        personal: {
          name: this.name,
          region: this.address?.name || '',
          date_of_birth: this.dateOfBirth,
          phone: this.phone,
          email: this.email,
          education: this.education,
          place_of_work: this.placeOfWork
        },
        task: {
          text: this.text
        },
        agreements: {
          personal: this.isAgreement
        }
      }
    }
  }

  static rules() {
    return {
      name: requiredRule,
      address: requiredRule,
      dateOfBirth: requiredRule,
      phone: { ...requiredRule, ...phoneRule },
      email: { ...requiredRule, ...emailRule },
      education: emptyRule,
      placeOfWork: emptyRule,
      text: { ...requiredRule, ...minLengthCountRule(500) },
      isAgreement: checkedRule
    }
  }
}
