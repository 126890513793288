import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class LandingBannerButtonModel extends BaseModel {
  title: string = ''
  align: string = ''
  link: string = ''
  'is_show': boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.is_show) {
        this.is_show = Boolean(Number(data.is_show))
      }
    }
  }
}
