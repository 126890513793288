import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'
import { LandingBannerButtonModel } from '~/model/Landings/index'

export default class LandingMainBannerBlockModel extends BaseModel {
  name: 'main_banner_block' = 'main_banner_block'
  title: string = ''
  text?: string = ''
  logo?: string = ''
  button: LandingBannerButtonModel = new LandingBannerButtonModel({})
  'background_image': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.button) {
        this.button = new LandingBannerButtonModel(data.button)
      }
    }

    if (this.background_image) {
      this.background_image = new ImageService().cropImage(this.background_image, {})
    }

    if (this.logo) {
      this.logo = new ImageService().cropImage(this.logo, {})
    }
  }
}
