import type { MetaInfo } from 'vue-meta'

export default class Seo {
  public static getHeaderMeta(page: any): MetaInfo {
    const title = page.seo_title

    const meta = [
      {
        hid: 'description',
        name: 'description',
        content: page.seo_description
      },
      {
        hid: 'og-title',
        property: 'og:title',
        content: page.seo_title
      },
      {
        hid: 'og-description',
        property: 'og:description',
        content: page.seo_description
      },
      {
        hid: 'og-type',
        property: 'og:type',
        content: page.page_type === 'news' ? 'article' : 'website'
      },
      {
        hid: 'og-url',
        property: 'og:url',
        content: page.url
      },
      {
        hid: 'twitter-title',
        property: 'twitter:title',
        content: page.seo_title
      },
      {
        hid: 'twitter-description',
        property: 'twitter:description',
        content: page.seo_description
      },
      {
        hid: 'twitter-type',
        property: 'twitter:type',
        content: page.page_type === 'news' ? 'article' : 'website'
      },
      {
        hid: 'twitter-url',
        property: 'twitter:url',
        content: page.url
      }
    ]

    if (page.image) {
      meta.push({ hid: 'og-image', property: 'og:image', content: page.image })
      meta.push({ hid: 'twitter-image', property: 'twitter:image', content: page.image })
      meta.push({ hid: 'twitter-card', property: 'twitter:card', content: 'summary_large_image' })
    }

    return {
      title,
      meta
    }
  }
}
