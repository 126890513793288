import { Middleware } from '@nuxt/types'

const redirectToChPU: Middleware = ({ route, redirect }) => {
  if (route.path === '/vacancies/company/rosselkhozbank') {
    redirect('/vacancies/region-russia/kompaniya-rosselkhozbank')
  }

  if (route.path === '/vacancies/company/kontaktnyy-tsentr-rosselkhozbank') {
    redirect('/vacancies/region-russia/kompaniya-kontaktnyy-tsentr-rosselkhozbank')
  }

  if (route.path === '/vacancies/company/rosselkhozbank/vacancy-stream/vakansii-digital-i-it') {
    redirect('/vacancies/region-russia/kompaniya-ooo-rskhb-intekh')
  }
}

export default redirectToChPU
