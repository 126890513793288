import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { FilterFields } from '~/model'
import { SortFieldModel } from '~/model/Filters'

@Module({
  namespaced: true,
  stateFactory: true,
  name: 'filterNews'
})
export class FilterNewsModule extends VuexModule {
  private filterFields: FilterFields[] = [new FilterFields()]
  private filterLoader: boolean = false
  private filterSkeleton: boolean = false
  private sortValue: string = ''
  private sortFields: Array<SortFieldModel> = []
  private activeCategory: string = ''
  private filterUrlQuery: Record<string, string | string[]> = {}

  @Mutation
  private mutationSetFilterUrlQuery(filterUrlQuery): void {
    this.filterUrlQuery = filterUrlQuery
  }

  @Mutation
  private mutationSetFilterFields(filterFields: FilterFields[]): void {
    this.filterFields = filterFields
  }

  @Mutation
  private mutationSetFilterLoader(filterLoader: boolean): void {
    this.filterLoader = filterLoader
  }

  @Mutation
  private mutationSetFilterSkeleton(filterSkeleton: boolean): void {
    this.filterSkeleton = filterSkeleton
  }

  @Mutation
  private mutationSetSortValue(value: string): void {
    this.sortValue = value
  }

  @Mutation
  private mutationSetSortFields(fields: Array<SortFieldModel>): void {
    this.sortFields = fields
  }

  @Mutation
  private mutationCategory(category: string): void {
    this.activeCategory = category
  }

  @Action
  public setFilterUrlQuery(filterUrlQuery): void {
    this.context.commit('mutationSetFilterUrlQuery', filterUrlQuery)
  }

  @Action
  public setSortValue(value: string): void {
    this.context.commit('mutationSetSortValue', value)
  }

  @Action
  public setFilterFields(filterBlocks: FilterFields[]): void {
    const idx = filterBlocks.findIndex(i => i.type === 'sort')
    if (idx >= 0) {
      const items = filterBlocks[idx].items
      if (Array.isArray(items)) {
        this.context.commit(
          'mutationSetSortFields',
          items.map(i => new SortFieldModel(i))
        )
      }
    }

    this.context.commit(
      'mutationSetFilterFields',
      filterBlocks.filter((_, i) => idx !== i) // eslint-disable-line @typescript-eslint/naming-convention
    )
  }

  @Action
  public setFilterLoader(loader: boolean): void {
    this.context.commit('mutationSetFilterLoader', loader)
  }

  @Action
  public setFilterSkeleton(skeleton: boolean): void {
    this.context.commit('mutationSetFilterSkeleton', skeleton)
  }

  @Action
  public setSortFields(fields: Array<SortFieldModel>): void {
    this.context.commit('mutationSetSortFields', fields)
  }

  @Action
  public setCategory(category: string): void {
    this.context.commit('mutationCategory', category)
  }

  get getFilterUrlQuery() {
    return this.filterUrlQuery
  }

  get getSortValue(): string {
    return this.sortValue
  }

  get getFilterFields(): FilterFields[] {
    return this.filterFields
  }

  get getFilterLoader(): Boolean {
    return this.filterLoader
  }

  get getFilterSkeleton(): Boolean {
    return this.filterSkeleton
  }

  get getSortFields(): Array<SortFieldModel> {
    return this.sortFields
  }

  get allowedFilterKeys(): Array<string> {
    const keys: Array<string> = []
    this.filterFields.forEach(field => {
      if (field.type === 'checkbox') {
        field.items?.forEach(i => keys.push(i.id))
      } else if (field.type === 'multiselect' && field.name) {
        keys.push(field.name)
      }
    })

    return keys
  }

  get allowedSortKeys(): Array<string> {
    return this.sortFields.map(field => field.id)
  }

  get category(): string {
    return this.activeCategory
  }
}
