import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import {
  BookModule,
  BookSortingModule,
  CollegeDirectionModule,
  CollegeModule,
  CompetitionsModule,
  ConferencesModule,
  CourseModule,
  DirectionModule,
  EventModule,
  FilterModule,
  FutureProfModule,
  GrantsFaqModule,
  GrantsModule,
  GrantsSlidesModule,
  GrantsSortingModule,
  MarathonModule,
  PageHistoryModule,
  PageMetadataModule,
  ProfessionalEducationDirectionModule,
  ProfessionalEducationModule,
  ProfessionsModule,
  QuantumModule,
  RegionLocationModule,
  RegionModule,
  SchoolDirectionsModule,
  SchoolMenuModule,
  SchoolModule,
  SearchModule,
  StatisticBlockModule,
  SubscribeBlockModule,
  TagCloudModule,
  UniversityDirectionModule,
  UniversityModule,
  VacanciesModule,
  WorkCardsModule,
  AmbassadorsModule,
  PartnerModule,
  StudentsModule,
  NotificationModule,
  DigitalProfileModule,
  SchoolchildrenModule,
  FilterNewsModule,
  TestingModule,
  EmployerModule,
  EmployersTasksModule,
  CareerRoutesModule,
  NewsModule,
  AuthModule,
  AccountModule,
  SocialModule,
  GlobalSearchModule,
  DigitalProfilesFiltersModule,
  LayoutModule
} from '~/store/modules'
import { CareerNavigatorModule } from '~/store/modules/CareerNavigator/CareerNavigator'

export interface TypedStore {
  auth: AuthModule
  professions: ProfessionsModule
  workCards: WorkCardsModule
  futureProf: FutureProfModule
  news: NewsModule
  school: SchoolModule
  competitions: CompetitionsModule
  conferences: ConferencesModule
  tagcloud: TagCloudModule
  subscribeBlock: SubscribeBlockModule
  direction: DirectionModule
  digitalProfilesFilters: DigitalProfilesFiltersModule
  book: BookModule
  marathon: MarathonModule
  employer: EmployerModule
  employersTasks: EmployersTasksModule
  event: EventModule
  bookSorting: BookSortingModule
  filter: FilterModule
  statisticBlock: StatisticBlockModule
  search: SearchModule
  quantums: QuantumModule
  grants: GrantsModule
  grantsSlides: GrantsSlidesModule
  grantsSorting: GrantsSortingModule
  grantsFaq: GrantsFaqModule
  schoolMenu: SchoolMenuModule
  schoolDirections: SchoolDirectionsModule
  university: UniversityModule
  universityDirection: UniversityDirectionModule
  college: CollegeModule
  professionalEducation: ProfessionalEducationModule
  professionalEducationDirection: ProfessionalEducationDirectionModule
  collegeDirection: CollegeDirectionModule
  course: CourseModule
  pageHistory: PageHistoryModule
  pageMetadata: PageMetadataModule
  region: RegionModule
  regionLocation: RegionLocationModule
  vacancies: VacanciesModule
  ambassadors: AmbassadorsModule
  partner: PartnerModule
  students: StudentsModule
  notification: NotificationModule
  digitalProfile: DigitalProfileModule
  schoolchildren: SchoolchildrenModule
  filterNews: FilterNewsModule
  account: AccountModule
  social: SocialModule
  testing: TestingModule
  globalSearch: GlobalSearchModule
  layout: LayoutModule
  careerNavigator: CareerNavigatorModule
  careerRoutes: CareerRoutesModule
}

const initializeStore: (store: Store<any>) => TypedStore = (store: Store<any>) => ({
  auth: getModule(AuthModule, store),
  professions: getModule(ProfessionsModule, store),
  workCards: getModule(WorkCardsModule, store),
  futureProf: getModule(FutureProfModule, store),
  news: getModule(NewsModule, store),
  school: getModule(SchoolModule, store),
  conferences: getModule(ConferencesModule, store),
  competitions: getModule(CompetitionsModule, store),
  tagcloud: getModule(TagCloudModule, store),
  subscribeBlock: getModule(SubscribeBlockModule, store),
  direction: getModule(DirectionModule, store),
  digitalProfilesFilters: getModule(DigitalProfilesFiltersModule, store),
  book: getModule(BookModule, store),
  event: getModule(EventModule, store),
  employer: getModule(EmployerModule, store),
  employersTasks: getModule(EmployersTasksModule, store),
  bookSorting: getModule(BookSortingModule, store),
  filter: getModule(FilterModule, store),
  statisticBlock: getModule(StatisticBlockModule, store),
  search: getModule(SearchModule, store),
  quantums: getModule(QuantumModule, store),
  grants: getModule(GrantsModule, store),
  grantsSlides: getModule(GrantsSlidesModule, store),
  grantsSorting: getModule(GrantsSortingModule, store),
  grantsFaq: getModule(GrantsFaqModule, store),
  schoolMenu: getModule(SchoolMenuModule, store),
  schoolDirections: getModule(SchoolDirectionsModule, store),
  university: getModule(UniversityModule, store),
  universityDirection: getModule(UniversityDirectionModule, store),
  college: getModule(CollegeModule, store),
  professionalEducation: getModule(ProfessionalEducationModule, store),
  professionalEducationDirection: getModule(ProfessionalEducationDirectionModule, store),
  collegeDirection: getModule(CollegeDirectionModule, store),
  course: getModule(CourseModule, store),
  marathon: getModule(MarathonModule, store),
  pageHistory: getModule(PageHistoryModule, store),
  pageMetadata: getModule(PageMetadataModule, store),
  region: getModule(RegionModule, store),
  regionLocation: getModule(RegionLocationModule, store),
  vacancies: getModule(VacanciesModule, store),
  ambassadors: getModule(AmbassadorsModule, store),
  partner: getModule(PartnerModule, store),
  students: getModule(StudentsModule, store),
  notification: getModule(NotificationModule, store),
  digitalProfile: getModule(DigitalProfileModule, store),
  schoolchildren: getModule(SchoolchildrenModule, store),
  filterNews: getModule(FilterNewsModule, store),
  account: getModule(AccountModule, store),
  social: getModule(SocialModule, store),
  testing: getModule(TestingModule, store),
  globalSearch: getModule(GlobalSearchModule, store),
  layout: getModule(LayoutModule, store),
  careerNavigator: getModule(CareerNavigatorModule, store),
  careerRoutes: getModule(CareerRoutesModule, store)
})

export { initializeStore }
