import { ApiService } from '~/shared/api'
import { getList } from '~/shared/utils/decorators'
import { ServiceHasList } from '~/shared/interfaces'

// eslint-disable-next-line
interface CareerRouteService extends ServiceHasList {}

// @ts-ignore
@getList

// eslint-disable-next-line no-redeclare
class CareerRouteService extends ApiService {
  protected route: string = 'career-routes'
}

export default CareerRouteService
