import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class UniversityMiniCardModel extends BaseModel {
  slug: string = ''
  name?: string = ''
  'short_name': string = ''
  logo?: string = ''
  image?: string = ''
  'budget_avg_score'?: string = ''
  'budget_places_number'?: number = 0

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.image) {
        this.image = new ImageService().cropImage(data.image, {})
      }
      if (data.logo) {
        this.logo = new ImageService().cropImage(data.logo, {})
      }
    }
  }
}
