import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class CareerRoutesDetailSkillItemModel extends BaseModel {
  name: string = ''
  image: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.image) {
        this.image = new ImageService().cropImage(data.image, {})
      }
    }
  }
}
