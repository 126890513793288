
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import NotFound from '~/components/Error/NotFound.vue'
import NotFoundVacancy from '~/components/Error/NotFoundVacancy.vue'

@Component({
  components: {
    NotFound,
    NotFoundVacancy
  }
})
export default class ErrorPage extends Vue {
  @Prop({
    default: () => {}
  })
  error!: any

  get currentErrorComponent() {
    return this.error && this.error.path && this.error.path.includes('/vacancies/') ? 'NotFoundVacancy' : 'NotFound'
  }
}
