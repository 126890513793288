import { AxiosResponse } from 'axios'
import { ApiService } from '~/shared/api'
import { UnknownObject } from '~/shared/interfaces'

// eslint-disable-next-line no-redeclare
class WomenAPKService extends ApiService {
  protected route = 'contests/applications/send'

  public async sendForm<T>(form: UnknownObject): Promise<T> {
    const { status }: any = await this.requestService.request<AxiosResponse<T>>({
      data: form,
      method: 'POST',
      url: `${this.prefix}/${this.route}`
    })
    return status
  }
}

export default WomenAPKService
