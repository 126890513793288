import type { ConfigType, Dayjs, OpUnitType } from 'dayjs'
import dayjs from 'dayjs'
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/ru'

dayjs.extend(localizedFormatPlugin)
dayjs.locale('ru')

export function toDateFormat(value: ConfigType): string {
  return dayjs(value).format('LL')
}

export function toDateISO8601Format(value: ConfigType): string {
  return dayjs(value).format('YYYY-MM-DD')
}

export function toDateShortFormat(value: ConfigType): string {
  return dayjs(value).format('DD.MM')
}

export function addMonths(date: ConfigType, count: number): Dayjs {
  return dayjs(date).add(count, 'month')
}

export function toDateStartOf(value: ConfigType, unit: OpUnitType): Date {
  return dayjs(value).startOf(unit).toDate()
}

export function isFutureDate(value: ConfigType, compareDate?: Date): boolean {
  return toDateStartOf(value, 'day') > toDateStartOf(compareDate || new Date(), 'day')
}
