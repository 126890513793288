import { AxiosResponse } from 'axios'
import { SimilarService } from '.'
import { RequestService } from '~/services/RequestService'
import { GeolocationService } from '~/services/GeolocationService'
import { COOKIE_PATH, COOKIE_TWO_MONTH, COOKIE_VACANCIES_RESPONSE } from '~/shared/const'
import { ApiService } from '~/shared/api'
import { isNumber, isUnknownObject } from '~/shared/utils/guards'

export default class VacanciesService extends ApiService {
  protected route: string = 'vacancies'
  public readonly similarService: SimilarService

  private geolocation: GeolocationService
  private cookies: any

  constructor(requestService: RequestService, geolocation: GeolocationService, cookies: any) {
    super(requestService)
    this.geolocation = geolocation
    this.cookies = cookies
    this.similarService = new SimilarService(requestService)
  }

  readonly urls = {
    groupVacancies: 'api/v1/yavagro/vacancies/',
    regionTop: 'api/v1/yavagro/vacancies/topregions/',
    blocksVacancies: '/api/v1/yavagro/vacancies/',
    filterFields: '/api/v1/yavagro/vacancies/filter-fields',
    filterFieldsInternship: '/api/v1/yavagro/stazhirovka/filter-fields',
    topVacancies: '/api/v1/yavagro/vacancies/top',
    topInterships: '/api/v1/yavagro/vacancies/topinternships',
    similarVacancies: '/api/v1/yavagro/vacancies/similar',
    internshipByProfession: '/api/v1/yavagro/stazhirovka/profession',
    internshipByCompany: '/api/v1/yavagro/stazhirovka/company',
    vacanciesByCompany: 'api/v1/yavagro/vacancies/company',
    countCompanies: '/api/v1/yavagro/vacancies/companies/count',
    countResumes: '/api/v1/yavagro/resumes/count'
  }

  async getVacanciesGroupByRegion<T>(region: string): Promise<T> {
    const url = `${this.urls.groupVacancies}${region}?is_display_in_main=1`
    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getInternshipByProfessionSlug<T>(slug: string, query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')
    const url = `${this.urls.internshipByProfession}/${slug}?${q}`
    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getInternshipByCompanySlug<T>(slug: string, query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')
    const url = `${this.urls.internshipByCompany}/${slug}?${q}`
    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getVacanciesByCompanySlug<T>(slug: string, query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')
    const url = `${this.urls.vacanciesByCompany}/${slug}?${q}`
    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getVacanciesByCompanySlugAndStream<T>(slug: string, stream: string, query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')
    const url = `${this.urls.vacanciesByCompany}/${slug}/vacancy-stream/${stream}?${q}`
    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getTopRegions<T>(region: string): Promise<T> {
    const url = `${this.urls.regionTop}${region}`
    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data
  }

  async getRecomendedBlockVacancies<T>(region: string): Promise<T> {
    const url = `${this.urls.blocksVacancies}${region}?is_display_in_recommended=1`
    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getProfessionBlockVacancies<T>(region: string): Promise<T> {
    const url = `${this.urls.blocksVacancies}${region}?is_display_in_professions=1`
    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  public async getFilterFields<T>(query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')
    const res: any = await this.requestService.request<T>({
      url: `${this.urls.filterFields}?${q}`
    })

    return res.data.data
  }

  public async getFilterFieldsInternship<T>(query: object): Promise<T> {
    const q = Object.keys(query)
      .map(key => `${key}=${encodeURI(query[key])}`)
      .join('&')
    const res: any = await this.requestService.request<T>({
      url: `${this.urls.filterFieldsInternship}?${q}`
    })

    return res.data.data
  }

  public async getGroupInfo<T>(group: string): Promise<T> {
    const res: any = await this.requestService.request<T>({
      url: `${this.urls.blocksVacancies}info/?group=${group}`
    })

    return res.data.data
  }

  async getTitlePage<T>(query): Promise<T> {
    const region = this.geolocation.getCookieRegion()
    let url = `${this.urls.groupVacancies}title?region=russia`
    if (region && region.slug === query.region) {
      url = `${this.urls.groupVacancies}title?region=${region.slug}`
    } else {
      const q = Object.keys(query)
        .map(key => `${key}=${encodeURI(query[key])}`)
        .join('&')
      url = `${this.urls.groupVacancies}title?${q}`
    }

    const res: any = await this.requestService.request<T>({
      url
    })
    return res.data.data.title
  }

  async getTopVacancies<T>(): Promise<T> {
    const region = this.geolocation.getCookieRegion()
    let url = `${this.urls.topVacancies}?region=russia`
    if (region && region.slug) {
      url = `${this.urls.topVacancies}?region=${region.slug}`
    }

    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getTopInterships<T>(): Promise<T> {
    const region = this.geolocation.getCookieRegion()
    let url = `${this.urls.topInterships}?region=russia`
    if (region && region.slug) {
      url = `${this.urls.topInterships}?region=${region.slug}`
    }

    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getTopVacanciesCompany<T>(slug: string): Promise<T> {
    const region = this.geolocation.getCookieRegion()
    let url = `${this.urls.topVacancies}?region=russia`
    if (region && region.slug) {
      url = `${this.urls.topVacancies}?region=${region.slug}`
    }
    if (slug) {
      url += `&company=${encodeURIComponent(slug)}`
    }

    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getTopIntershipsCompany<T>(slug: string): Promise<T> {
    const region = this.geolocation.getCookieRegion()
    let url = `${this.urls.topInterships}?region=russia`
    if (region && region.slug) {
      url = `${this.urls.topInterships}?region=${region.slug}`
    }
    if (slug) {
      url += `&company=${encodeURIComponent(slug)}`
    }

    const res = await this.requestService.request<AxiosResponse<T>>({ url })
    return res.data.data
  }

  async getCountCompanies<T>(): Promise<number> {
    const res = await this.requestService.request<AxiosResponse<T>>({
      url: this.urls.countCompanies
    })

    return isUnknownObject(res.data) && isUnknownObject(res.data.result) && isNumber(res.data.result.total)
      ? Number(res.data.result.total)
      : 0
  }

  async getCountResumes<T>(): Promise<number> {
    const res = await this.requestService.request<AxiosResponse<T>>({
      url: this.urls.countResumes
    })

    return isUnknownObject(res.data) && isUnknownObject(res.data.result) && isNumber(res.data.result.total)
      ? Number(res.data.result.total)
      : 0
  }

  setVacanciesResponseByUser(id: number) {
    const currentResponses = this.getVacanciesResponseForCookie()

    if (currentResponses === null) {
      const newResponses = [id]
      this.saveVacancyResponseToCookie(newResponses)
    } else if (!currentResponses.includes(id)) {
      currentResponses.push(id)
      this.saveVacancyResponseToCookie(currentResponses)
    }
  }

  public isVacancyInResponses(id: number): boolean {
    const currentResponses = this.getVacanciesResponseForCookie()
    if (currentResponses === null) {
      return false
    }
    return currentResponses.includes(id)
  }

  private getVacanciesResponseForCookie(): number[] | null {
    const storedResponses = this.cookies.get(COOKIE_VACANCIES_RESPONSE)
    return storedResponses || null
  }

  private saveVacancyResponseToCookie(responses: number[]): void {
    this.cookies.set(COOKIE_VACANCIES_RESPONSE, JSON.stringify(responses), {
      path: COOKIE_PATH,
      maxAge: COOKIE_TWO_MONTH
    })
  }
}
