import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'

export default class SocialNetworkGroupModel extends BaseModel {
  public id: number = 0
  public name: string = ''
  public externalId: string = ''
  public url: string = ''
  public isEnabled: boolean = false
  public isAdded: boolean = false
  public isUserCanPost: boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
