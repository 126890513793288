import { RequestService } from '~/services/RequestService'

class CareerRoutesCategoryService {
  readonly url: string = '/api/v1/yavagro/career-routes/directions'

  constructor(private requestService: RequestService) {}

  public async getCategories<T>(): Promise<T> {
    const res: any = await this.requestService.request<T>({
      url: this.url
    })

    return res.data.result
  }
}

export default CareerRoutesCategoryService
