export class VacanciesModel {
  search?: string
  sort?: string
  page?: number | string
  group?: string
  region?: string
  regions?: string
  city?: string
  url?: string
  'per_page'?: number
  'status_id'?: string
  'price-min'?: string
  'price-max'?: string
  'have-hostel'?: string
  'housing-provided'?: string
  'housing-not-provided'?: string
  'not-have-rf'?: string
  'driver-license-a'?: string
  'driver-license-b'?: string
  'driver-license-c'?: string
  'driver-license-d'?: string
  'driver-license-e'?: string
  'driver-license-f'?: string
  'is_stazhirovka'?: string
  'work-stazhirovka'?: string

  company?: string
}
