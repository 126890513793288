import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10edc8e1 = () => interopDefault(import('../pages/vacancies/id.vue' /* webpackChunkName: "" */))
const _079375d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5abaec02 = () => interopDefault(import('../pages/agroleader/index.vue' /* webpackChunkName: "pages/agroleader/index" */))
const _d9045c78 = () => interopDefault(import('../pages/applicants/index.vue' /* webpackChunkName: "pages/applicants/index" */))
const _5c26b75c = () => interopDefault(import('../pages/application-for-bank-product/index.vue' /* webpackChunkName: "pages/application-for-bank-product/index" */))
const _8bb42fe6 = () => interopDefault(import('../pages/books/index.vue' /* webpackChunkName: "pages/books/index" */))
const _3525ee0b = () => interopDefault(import('../pages/calculator-ege/index.vue' /* webpackChunkName: "pages/calculator-ege/index" */))
const _0ba82b5a = () => interopDefault(import('../pages/career-marathone/index.vue' /* webpackChunkName: "pages/career-marathone/index" */))
const _8fd552e2 = () => interopDefault(import('../pages/career-marathone-2023/index.vue' /* webpackChunkName: "pages/career-marathone-2023/index" */))
const _cc94b7ca = () => interopDefault(import('../pages/career-navigator/index.vue' /* webpackChunkName: "pages/career-navigator/index" */))
const _76dff71c = () => interopDefault(import('../pages/career-routes/index.vue' /* webpackChunkName: "pages/career-routes/index" */))
const _11dabe0f = () => interopDefault(import('../pages/colleges/index.vue' /* webpackChunkName: "pages/colleges/index" */))
const _7b9add9b = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _30f859d7 = () => interopDefault(import('../pages/digital-profile/index.vue' /* webpackChunkName: "pages/digital-profile/index" */))
const _7ca7a927 = () => interopDefault(import('../pages/directions/index.vue' /* webpackChunkName: "pages/directions/index" */))
const _fa243f64 = () => interopDefault(import('../pages/employer/index.vue' /* webpackChunkName: "pages/employer/index" */))
const _9de2a9a8 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _19448c12 = () => interopDefault(import('../pages/for-employers/index.vue' /* webpackChunkName: "pages/for-employers/index" */))
const _75ba1f70 = () => interopDefault(import('../pages/knowledge-day/index.vue' /* webpackChunkName: "pages/knowledge-day/index" */))
const _568d4762 = () => interopDefault(import('../pages/looking-for-internship/index.vue' /* webpackChunkName: "pages/looking-for-internship/index" */))
const _0c2fbaf2 = () => interopDefault(import('../pages/looking-for-job/index.vue' /* webpackChunkName: "pages/looking-for-job/index" */))
const _fba9bb34 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _434fd7c0 = () => interopDefault(import('../pages/olympiad/index.vue' /* webpackChunkName: "pages/olympiad/index" */))
const _21494ba8 = () => interopDefault(import('../pages/professional-development/index.vue' /* webpackChunkName: "pages/professional-development/index" */))
const _4e866356 = () => interopDefault(import('../pages/professional-education/index.vue' /* webpackChunkName: "pages/professional-education/index" */))
const _09ccdbcc = () => interopDefault(import('../pages/professions/index.vue' /* webpackChunkName: "pages/professions/index" */))
const _24545ffc = () => interopDefault(import('../pages/profitable-deposit/index.vue' /* webpackChunkName: "pages/profitable-deposit/index" */))
const _c7fa700c = () => interopDefault(import('../pages/proforientation/index.vue' /* webpackChunkName: "pages/proforientation/index" */))
const _738344a1 = () => interopDefault(import('../pages/resume-list/index.vue' /* webpackChunkName: "pages/resume-list/index" */))
const _71b69029 = () => interopDefault(import('../pages/salt/index.vue' /* webpackChunkName: "pages/salt/index" */))
const _750421f4 = () => interopDefault(import('../pages/schoolchildren/index.vue' /* webpackChunkName: "pages/schoolchildren/index" */))
const _f28e1342 = () => interopDefault(import('../pages/schoolfarmers/index.vue' /* webpackChunkName: "pages/schoolfarmers/index" */))
const _164a5bd7 = () => interopDefault(import('../pages/stazhirovka/index.vue' /* webpackChunkName: "pages/stazhirovka/index" */))
const _068d8eeb = () => interopDefault(import('../pages/students/index.vue' /* webpackChunkName: "pages/students/index" */))
const _ed056e1c = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _53c2b3ff = () => interopDefault(import('../pages/universities/index.vue' /* webpackChunkName: "pages/universities/index" */))
const _21b06cfc = () => interopDefault(import('../pages/vacancies/index.vue' /* webpackChunkName: "pages/vacancies/index" */))
const _9a8ceec4 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _373344ee = () => interopDefault(import('../pages/volonteram/index.vue' /* webpackChunkName: "pages/volonteram/index" */))
const _1e49cd20 = () => interopDefault(import('../pages/women-apk/index.vue' /* webpackChunkName: "pages/women-apk/index" */))
const _54c580c4 = () => interopDefault(import('../pages/account/candidate/index.vue' /* webpackChunkName: "pages/account/candidate/index" */))
const _67b7c762 = () => interopDefault(import('../pages/account/executive-search/index.vue' /* webpackChunkName: "pages/account/executive-search/index" */))
const _6297ed43 = () => interopDefault(import('../pages/account/university-statistics/index.vue' /* webpackChunkName: "pages/account/university-statistics/index" */))
const _fdf59bcc = () => interopDefault(import('../pages/account/vacancies/index.vue' /* webpackChunkName: "pages/account/vacancies/index" */))
const _7769a23b = () => interopDefault(import('../pages/career-marathone-2023/video/index.vue' /* webpackChunkName: "pages/career-marathone-2023/video/index" */))
const _15c72bc7 = () => interopDefault(import('../pages/digital-profile/create.vue' /* webpackChunkName: "pages/digital-profile/create" */))
const _01149ad6 = () => interopDefault(import('../pages/digital-profile/edit.vue' /* webpackChunkName: "pages/digital-profile/edit" */))
const _6ec67d76 = () => interopDefault(import('../pages/events/scholarship-program/index.vue' /* webpackChunkName: "pages/events/scholarship-program/index" */))
const _2e056be2 = () => interopDefault(import('../pages/events/smart-route/index.vue' /* webpackChunkName: "pages/events/smart-route/index" */))
const _091113ca = () => interopDefault(import('../pages/for-employers/executive-search/index.vue' /* webpackChunkName: "pages/for-employers/executive-search/index" */))
const _7c5e3b26 = () => interopDefault(import('../pages/for-employers/student-activities/index.vue' /* webpackChunkName: "pages/for-employers/student-activities/index" */))
const _5dd0aee5 = () => interopDefault(import('../pages/olympiad/contest/index.vue' /* webpackChunkName: "pages/olympiad/contest/index" */))
const _b41a74c6 = () => interopDefault(import('../pages/olympiad/festival/index.vue' /* webpackChunkName: "pages/olympiad/festival/index" */))
const _489f5719 = () => interopDefault(import('../pages/olympiad/school/index.vue' /* webpackChunkName: "pages/olympiad/school/index" */))
const _0d81d0ac = () => interopDefault(import('../pages/olympiad/win.vue' /* webpackChunkName: "pages/olympiad/win" */))
const _464bc69a = () => interopDefault(import('../pages/proforientation/test.vue' /* webpackChunkName: "pages/proforientation/test" */))
const _64a3ab7c = () => interopDefault(import('../pages/schoolchildren/financial-literacy-test.vue' /* webpackChunkName: "pages/schoolchildren/financial-literacy-test" */))
const _656e2d5e = () => interopDefault(import('../pages/students/ambassadors/index.vue' /* webpackChunkName: "pages/students/ambassadors/index" */))
const _36538dcd = () => interopDefault(import('../pages/videos/marafon-karery-2022-s-alyonoi-vladimirskoj.vue' /* webpackChunkName: "pages/videos/marafon-karery-2022-s-alyonoi-vladimirskoj" */))
const _fa61b87c = () => interopDefault(import('../pages/videos/translyaciya-marafona-karery-stipendialnaya-programma.vue' /* webpackChunkName: "pages/videos/translyaciya-marafona-karery-stipendialnaya-programma" */))
const _6b6b0903 = () => interopDefault(import('../pages/account/candidate/feedbacks/index.vue' /* webpackChunkName: "pages/account/candidate/feedbacks/index" */))
const _1a643c4a = () => interopDefault(import('../pages/account/candidate/resumes/index.vue' /* webpackChunkName: "pages/account/candidate/resumes/index" */))
const _a3d26276 = () => interopDefault(import('../pages/account/publish/news.vue' /* webpackChunkName: "pages/account/publish/news" */))
const _2563db15 = () => interopDefault(import('../pages/account/publish/settings.vue' /* webpackChunkName: "pages/account/publish/settings" */))
const _71554838 = () => interopDefault(import('../pages/account/vacancies/create.vue' /* webpackChunkName: "pages/account/vacancies/create" */))
const _8d54f24e = () => interopDefault(import('../pages/account/vacancies/feedbacks/index.vue' /* webpackChunkName: "pages/account/vacancies/feedbacks/index" */))
const _3acfb177 = () => interopDefault(import('../pages/events/scholarship-program/application/index.vue' /* webpackChunkName: "pages/events/scholarship-program/application/index" */))
const _18942d68 = () => interopDefault(import('../pages/students/ambassadors/employers-tasks/index.vue' /* webpackChunkName: "pages/students/ambassadors/employers-tasks/index" */))
const _e2bab77a = () => interopDefault(import('../pages/account/candidate/resumes/create.vue' /* webpackChunkName: "pages/account/candidate/resumes/create" */))
const _541ff73c = () => interopDefault(import('../pages/account/candidate/resumes/edit/_id.vue' /* webpackChunkName: "pages/account/candidate/resumes/edit/_id" */))
const _0d1d702b = () => interopDefault(import('../pages/account/candidate/feedbacks/_id.vue' /* webpackChunkName: "pages/account/candidate/feedbacks/_id" */))
const _b6dec1fa = () => interopDefault(import('../pages/account/candidate/resumes/_id.vue' /* webpackChunkName: "pages/account/candidate/resumes/_id" */))
const _2b034a9d = () => interopDefault(import('../pages/account/vacancies/edit/_id.vue' /* webpackChunkName: "pages/account/vacancies/edit/_id" */))
const _abcd28fe = () => interopDefault(import('../pages/account/vacancies/feedbacks/_id.vue' /* webpackChunkName: "pages/account/vacancies/feedbacks/_id" */))
const _56187aa3 = () => interopDefault(import('../pages/students/ambassadors/employers-tasks/_slug/index.vue' /* webpackChunkName: "pages/students/ambassadors/employers-tasks/_slug/index" */))
const _408cf302 = () => interopDefault(import('../pages/account/vacancies/_id.vue' /* webpackChunkName: "pages/account/vacancies/_id" */))
const _6a4914c6 = () => interopDefault(import('../pages/events/smart-route/_slug/index.vue' /* webpackChunkName: "pages/events/smart-route/_slug/index" */))
const _5c1a0d9d = () => interopDefault(import('../pages/olympiad/contest/_slug.vue' /* webpackChunkName: "pages/olympiad/contest/_slug" */))
const _b787b756 = () => interopDefault(import('../pages/olympiad/festival/_slug.vue' /* webpackChunkName: "pages/olympiad/festival/_slug" */))
const _46e8b5d1 = () => interopDefault(import('../pages/olympiad/school/_slug.vue' /* webpackChunkName: "pages/olympiad/school/_slug" */))
const _e7fd99fa = () => interopDefault(import('../pages/proforientation/result/_hash.vue' /* webpackChunkName: "pages/proforientation/result/_hash" */))
const _8f217276 = () => interopDefault(import('../pages/books/_slug.vue' /* webpackChunkName: "pages/books/_slug" */))
const _f2e9a552 = () => interopDefault(import('../pages/career-routes/_slug/index.vue' /* webpackChunkName: "pages/career-routes/_slug/index" */))
const _73389bec = () => interopDefault(import('../pages/colleges/_slug/index.vue' /* webpackChunkName: "pages/colleges/_slug/index" */))
const _c02cea7a = () => interopDefault(import('../pages/courses/_id.vue' /* webpackChunkName: "pages/courses/_id" */))
const _7af107df = () => interopDefault(import('../pages/directions/_slug.vue' /* webpackChunkName: "pages/directions/_slug" */))
const _fd9181f4 = () => interopDefault(import('../pages/employer/_slug.vue' /* webpackChunkName: "pages/employer/_slug" */))
const _a14fec38 = () => interopDefault(import('../pages/events/_slug.vue' /* webpackChunkName: "pages/events/_slug" */))
const _3c3238ad = () => interopDefault(import('../pages/looking-for-job/_slug/index.vue' /* webpackChunkName: "pages/looking-for-job/_slug/index" */))
const _ff16fdc4 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _366df5d0 = () => interopDefault(import('../pages/professional-education/_slug/index.vue' /* webpackChunkName: "pages/professional-education/_slug/index" */))
const _0d3a1e5c = () => interopDefault(import('../pages/professions/_slug.vue' /* webpackChunkName: "pages/professions/_slug" */))
const _3eabfb49 = () => interopDefault(import('../pages/resume-list/_id.vue' /* webpackChunkName: "pages/resume-list/_id" */))
const _29060dc1 = () => interopDefault(import('../pages/schoolchildren/_slug/index.vue' /* webpackChunkName: "pages/schoolchildren/_slug/index" */))
const _331e0baa = () => interopDefault(import('../pages/stazhirovka/_param1/index.vue' /* webpackChunkName: "pages/stazhirovka/_param1/index" */))
const _a4d1180c = () => interopDefault(import('../pages/universities/_slug/index.vue' /* webpackChunkName: "pages/universities/_slug/index" */))
const _251a1650 = () => interopDefault(import('../pages/vacancies/_param1/index.vue' /* webpackChunkName: "pages/vacancies/_param1/index" */))
const _a21bbd3a = () => interopDefault(import('../pages/videos/_slug.vue' /* webpackChunkName: "pages/videos/_slug" */))
const _a51c6512 = () => interopDefault(import('../pages/colleges/_slug/directions/_id.vue' /* webpackChunkName: "pages/colleges/_slug/directions/_id" */))
const _5bdf2f1e = () => interopDefault(import('../pages/professional-education/_slug/directions/_id.vue' /* webpackChunkName: "pages/professional-education/_slug/directions/_id" */))
const _da7d8cf2 = () => interopDefault(import('../pages/universities/_slug/directions/_id.vue' /* webpackChunkName: "pages/universities/_slug/directions/_id" */))
const _27cf9cf9 = () => interopDefault(import('../pages/gosuslugi-spinner/_token/_index.vue' /* webpackChunkName: "pages/gosuslugi-spinner/_token/_index" */))
const _6182ea35 = () => interopDefault(import('../pages/rshb/_section/_name.vue' /* webpackChunkName: "pages/rshb/_section/_name" */))
const _10d515ee = () => interopDefault(import('../pages/schoolchildren/_slug/_lesson/index.vue' /* webpackChunkName: "pages/schoolchildren/_slug/_lesson/index" */))
const _af1a9180 = () => interopDefault(import('../pages/stazhirovka/_param1/_param2/index.vue' /* webpackChunkName: "pages/stazhirovka/_param1/_param2/index" */))
const _1823de65 = () => interopDefault(import('../pages/vacancies/_param1/_param2/index.vue' /* webpackChunkName: "pages/vacancies/_param1/_param2/index" */))
const _1de94216 = () => interopDefault(import('../pages/stazhirovka/_param1/_param2/_param3/index.vue' /* webpackChunkName: "pages/stazhirovka/_param1/_param2/_param3/index" */))
const _2767743b = () => interopDefault(import('../pages/vacancies/_param1/_param2/_param3/index.vue' /* webpackChunkName: "pages/vacancies/_param1/_param2/_param3/index" */))
const _c340f25c = () => interopDefault(import('../pages/vacancies/_param1/_param2/_param3/_param4/index.vue' /* webpackChunkName: "pages/vacancies/_param1/_param2/_param3/_param4/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e8b03c2c = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/vacancies/:id(\\d+)",
    component: _10edc8e1,
    name: "vacancies-id"
  }, {
    path: "/account",
    component: _079375d0,
    name: "account"
  }, {
    path: "/agroleader",
    component: _5abaec02,
    name: "agroleader"
  }, {
    path: "/applicants",
    component: _d9045c78,
    name: "applicants"
  }, {
    path: "/application-for-bank-product",
    component: _5c26b75c,
    name: "application-for-bank-product"
  }, {
    path: "/books",
    component: _8bb42fe6,
    name: "books"
  }, {
    path: "/calculator-ege",
    component: _3525ee0b,
    name: "calculator-ege"
  }, {
    path: "/career-marathone",
    component: _0ba82b5a,
    name: "career-marathone"
  }, {
    path: "/career-marathone-2023",
    component: _8fd552e2,
    name: "career-marathone-2023"
  }, {
    path: "/career-navigator",
    component: _cc94b7ca,
    name: "career-navigator"
  }, {
    path: "/career-routes",
    component: _76dff71c,
    name: "career-routes"
  }, {
    path: "/colleges",
    component: _11dabe0f,
    name: "colleges"
  }, {
    path: "/courses",
    component: _7b9add9b,
    name: "courses"
  }, {
    path: "/digital-profile",
    component: _30f859d7,
    name: "digital-profile"
  }, {
    path: "/directions",
    component: _7ca7a927,
    name: "directions"
  }, {
    path: "/employer",
    component: _fa243f64,
    name: "employer"
  }, {
    path: "/events",
    component: _9de2a9a8,
    name: "events"
  }, {
    path: "/for-employers",
    component: _19448c12,
    name: "for-employers"
  }, {
    path: "/knowledge-day",
    component: _75ba1f70,
    name: "knowledge-day"
  }, {
    path: "/looking-for-internship",
    component: _568d4762,
    name: "looking-for-internship"
  }, {
    path: "/looking-for-job",
    component: _0c2fbaf2,
    name: "looking-for-job"
  }, {
    path: "/news",
    component: _fba9bb34,
    name: "news"
  }, {
    path: "/olympiad",
    component: _434fd7c0,
    name: "olympiad"
  }, {
    path: "/professional-development",
    component: _21494ba8,
    name: "professional-development"
  }, {
    path: "/professional-education",
    component: _4e866356,
    name: "professional-education"
  }, {
    path: "/professions",
    component: _09ccdbcc,
    name: "professions"
  }, {
    path: "/profitable-deposit",
    component: _24545ffc,
    name: "profitable-deposit"
  }, {
    path: "/proforientation",
    component: _c7fa700c,
    name: "proforientation"
  }, {
    path: "/resume-list",
    component: _738344a1,
    name: "resume-list"
  }, {
    path: "/salt",
    component: _71b69029,
    name: "salt"
  }, {
    path: "/schoolchildren",
    component: _750421f4,
    name: "schoolchildren"
  }, {
    path: "/schoolfarmers",
    component: _f28e1342,
    name: "schoolfarmers"
  }, {
    path: "/stazhirovka",
    component: _164a5bd7,
    name: "stazhirovka"
  }, {
    path: "/students",
    component: _068d8eeb,
    name: "students"
  }, {
    path: "/support",
    component: _ed056e1c,
    name: "support"
  }, {
    path: "/universities",
    component: _53c2b3ff,
    name: "universities"
  }, {
    path: "/vacancies",
    component: _21b06cfc,
    name: "vacancies"
  }, {
    path: "/video",
    component: _9a8ceec4,
    name: "video"
  }, {
    path: "/volonteram",
    component: _373344ee,
    name: "volonteram"
  }, {
    path: "/women-apk",
    component: _1e49cd20,
    name: "women-apk"
  }, {
    path: "/account/candidate",
    component: _54c580c4,
    name: "account-candidate"
  }, {
    path: "/account/executive-search",
    component: _67b7c762,
    name: "account-executive-search"
  }, {
    path: "/account/university-statistics",
    component: _6297ed43,
    name: "account-university-statistics"
  }, {
    path: "/account/vacancies",
    component: _fdf59bcc,
    name: "account-vacancies"
  }, {
    path: "/career-marathone-2023/video",
    component: _7769a23b,
    name: "career-marathone-2023-video"
  }, {
    path: "/digital-profile/create",
    component: _15c72bc7,
    name: "digital-profile-create"
  }, {
    path: "/digital-profile/edit",
    component: _01149ad6,
    name: "digital-profile-edit"
  }, {
    path: "/events/scholarship-program",
    component: _6ec67d76,
    name: "events-scholarship-program"
  }, {
    path: "/events/smart-route",
    component: _2e056be2,
    name: "events-smart-route"
  }, {
    path: "/for-employers/executive-search",
    component: _091113ca,
    name: "for-employers-executive-search"
  }, {
    path: "/for-employers/student-activities",
    component: _7c5e3b26,
    name: "for-employers-student-activities"
  }, {
    path: "/olympiad/contest",
    component: _5dd0aee5,
    name: "olympiad-contest"
  }, {
    path: "/olympiad/festival",
    component: _b41a74c6,
    name: "olympiad-festival"
  }, {
    path: "/olympiad/school",
    component: _489f5719,
    name: "olympiad-school"
  }, {
    path: "/olympiad/win",
    component: _0d81d0ac,
    name: "olympiad-win"
  }, {
    path: "/proforientation/test",
    component: _464bc69a,
    name: "proforientation-test"
  }, {
    path: "/schoolchildren/financial-literacy-test",
    component: _64a3ab7c,
    name: "schoolchildren-financial-literacy-test"
  }, {
    path: "/students/ambassadors",
    component: _656e2d5e,
    name: "students-ambassadors"
  }, {
    path: "/vacancies/id",
    component: _10edc8e1,
    name: "vacancies-id"
  }, {
    path: "/videos/marafon-karery-2022-s-alyonoi-vladimirskoj",
    component: _36538dcd,
    name: "videos-marafon-karery-2022-s-alyonoi-vladimirskoj"
  }, {
    path: "/videos/translyaciya-marafona-karery-stipendialnaya-programma",
    component: _fa61b87c,
    name: "videos-translyaciya-marafona-karery-stipendialnaya-programma"
  }, {
    path: "/account/candidate/feedbacks",
    component: _6b6b0903,
    name: "account-candidate-feedbacks"
  }, {
    path: "/account/candidate/resumes",
    component: _1a643c4a,
    name: "account-candidate-resumes"
  }, {
    path: "/account/publish/news",
    component: _a3d26276,
    name: "account-publish-news"
  }, {
    path: "/account/publish/settings",
    component: _2563db15,
    name: "account-publish-settings"
  }, {
    path: "/account/vacancies/create",
    component: _71554838,
    name: "account-vacancies-create"
  }, {
    path: "/account/vacancies/feedbacks",
    component: _8d54f24e,
    name: "account-vacancies-feedbacks"
  }, {
    path: "/events/scholarship-program/application",
    component: _3acfb177,
    name: "events-scholarship-program-application"
  }, {
    path: "/students/ambassadors/employers-tasks",
    component: _18942d68,
    name: "students-ambassadors-employers-tasks"
  }, {
    path: "/account/candidate/resumes/create",
    component: _e2bab77a,
    name: "account-candidate-resumes-create"
  }, {
    path: "/account/candidate/resumes/edit/:id?",
    component: _541ff73c,
    name: "account-candidate-resumes-edit-id"
  }, {
    path: "/account/candidate/feedbacks/:id",
    component: _0d1d702b,
    name: "account-candidate-feedbacks-id"
  }, {
    path: "/account/candidate/resumes/:id",
    component: _b6dec1fa,
    name: "account-candidate-resumes-id"
  }, {
    path: "/account/vacancies/edit/:id?",
    component: _2b034a9d,
    name: "account-vacancies-edit-id"
  }, {
    path: "/account/vacancies/feedbacks/:id",
    component: _abcd28fe,
    name: "account-vacancies-feedbacks-id"
  }, {
    path: "/students/ambassadors/employers-tasks/:slug",
    component: _56187aa3,
    name: "students-ambassadors-employers-tasks-slug"
  }, {
    path: "/account/vacancies/:id",
    component: _408cf302,
    name: "account-vacancies-id"
  }, {
    path: "/events/smart-route/:slug",
    component: _6a4914c6,
    name: "events-smart-route-slug"
  }, {
    path: "/olympiad/contest/:slug",
    component: _5c1a0d9d,
    name: "olympiad-contest-slug"
  }, {
    path: "/olympiad/festival/:slug",
    component: _b787b756,
    name: "olympiad-festival-slug"
  }, {
    path: "/olympiad/school/:slug",
    component: _46e8b5d1,
    name: "olympiad-school-slug"
  }, {
    path: "/proforientation/result/:hash?",
    component: _e7fd99fa,
    name: "proforientation-result-hash"
  }, {
    path: "/books/:slug",
    component: _8f217276,
    name: "books-slug"
  }, {
    path: "/career-routes/:slug",
    component: _f2e9a552,
    name: "career-routes-slug"
  }, {
    path: "/colleges/:slug",
    component: _73389bec,
    name: "colleges-slug"
  }, {
    path: "/courses/:id",
    component: _c02cea7a,
    name: "courses-id"
  }, {
    path: "/directions/:slug",
    component: _7af107df,
    name: "directions-slug"
  }, {
    path: "/employer/:slug",
    component: _fd9181f4,
    name: "employer-slug"
  }, {
    path: "/events/:slug",
    component: _a14fec38,
    name: "events-slug"
  }, {
    path: "/looking-for-job/:slug",
    component: _3c3238ad,
    name: "looking-for-job-slug"
  }, {
    path: "/news/:slug",
    component: _ff16fdc4,
    name: "news-slug"
  }, {
    path: "/professional-education/:slug",
    component: _366df5d0,
    name: "professional-education-slug"
  }, {
    path: "/professions/:slug",
    component: _0d3a1e5c,
    name: "professions-slug"
  }, {
    path: "/resume-list/:id?",
    component: _3eabfb49,
    name: "resume-list-id"
  }, {
    path: "/schoolchildren/:slug",
    component: _29060dc1,
    name: "schoolchildren-slug"
  }, {
    path: "/stazhirovka/:param1",
    component: _331e0baa,
    name: "stazhirovka-param1"
  }, {
    path: "/universities/:slug",
    component: _a4d1180c,
    name: "universities-slug"
  }, {
    path: "/vacancies/:param1",
    component: _251a1650,
    name: "vacancies-param1"
  }, {
    path: "/videos/:slug?",
    component: _a21bbd3a,
    name: "videos-slug"
  }, {
    path: "/colleges/:slug/directions/:id?",
    component: _a51c6512,
    name: "colleges-slug-directions-id"
  }, {
    path: "/professional-education/:slug?/directions/:id",
    component: _5bdf2f1e,
    name: "professional-education-slug-directions-id"
  }, {
    path: "/universities/:slug/directions/:id?",
    component: _da7d8cf2,
    name: "universities-slug-directions-id"
  }, {
    path: "/gosuslugi-spinner/:token?/:index",
    component: _27cf9cf9,
    name: "gosuslugi-spinner-token"
  }, {
    path: "/rshb/:section?/:name?",
    component: _6182ea35,
    name: "rshb-section-name"
  }, {
    path: "/schoolchildren/:slug/:lesson",
    component: _10d515ee,
    name: "schoolchildren-slug-lesson"
  }, {
    path: "/stazhirovka/:param1/:param2",
    component: _af1a9180,
    name: "stazhirovka-param1-param2"
  }, {
    path: "/vacancies/:param1/:param2",
    component: _1823de65,
    name: "vacancies-param1-param2"
  }, {
    path: "/stazhirovka/:param1/:param2/:param3",
    component: _1de94216,
    name: "stazhirovka-param1-param2-param3"
  }, {
    path: "/vacancies/:param1/:param2/:param3",
    component: _2767743b,
    name: "vacancies-param1-param2-param3"
  }, {
    path: "/vacancies/:param1/:param2/:param3/:param4",
    component: _c340f25c,
    name: "vacancies-param1-param2-param3-param4"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/:slug",
    component: _e8b03c2c,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
