import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'
import ProfessionCardColourfulModel from '~/model/Cards/ProfessionCardColourfulModel'
import {
  CareerRoutesDetailHistoryItemModel,
  CareerRoutesDetailSkillItemModel,
  CareerRoutesDetailAboutVacancyBlockModel
} from '~/model/CareerRoutes/index'
import NewsRecommendationCardModel from '~/model/Cards/NewsRecommendationCardModel'

export default class CareerRoutesDetailModel extends BaseModel {
  id: number = 0
  name: string = ''
  description: string = ''
  'card_image': string = ''
  'banner_image': string = ''
  'text_block_image_big': string = ''
  'text_block_image_small': string = ''
  'text_block_1_name': string = ''
  'text_block_1_description': string = ''
  'text_block_2_name': string = ''
  'text_block_2_description': string = ''
  'text_block_3_name': string = ''
  'text_block_3_description': string = ''
  'vacancies_data': CareerRoutesDetailAboutVacancyBlockModel = new CareerRoutesDetailAboutVacancyBlockModel({})
  'similar_professions': Array<ProfessionCardColourfulModel> = []
  quotes: Array<CareerRoutesDetailHistoryItemModel> = []
  'professional_skills': Array<CareerRoutesDetailSkillItemModel> = []
  news: Array<NewsRecommendationCardModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.vacancies_data) {
        this.vacancies_data = new CareerRoutesDetailAboutVacancyBlockModel(data.vacancies_data)
      }

      if (Array.isArray(data.similar_professions)) {
        this.similar_professions = data.similar_professions.map(i => new ProfessionCardColourfulModel(i))
      }

      if (Array.isArray(data.quotes)) {
        this.quotes = data.quotes.map(i => new CareerRoutesDetailHistoryItemModel(i))
      }

      if (Array.isArray(data.professional_skills)) {
        this.professional_skills = data.professional_skills.map(i => new CareerRoutesDetailSkillItemModel(i))
      }

      if (Array.isArray(data.news)) {
        this.news = data.news.map(i => new NewsRecommendationCardModel(i))
      }

      if (data.card_image) {
        this.card_image = new ImageService().cropImage(this.card_image, {})
      }

      if (data.banner_image) {
        this.banner_image = new ImageService().cropImage(this.banner_image, {})
      }

      if (data.text_block_image_big) {
        this.text_block_image_big = new ImageService().cropImage(data.text_block_image_big, {})
      }

      if (data.text_block_image_small) {
        this.text_block_image_small = new ImageService().cropImage(data.text_block_image_small, {})
      }

      if (data.banner_image) {
        this.banner_image = new ImageService().cropImage(data.banner_image, {})
      }
    }
  }
}
