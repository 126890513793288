import axios from 'axios'
import { DadataAddressSuggestionParams } from '~/interfaces/Dadata'
import { toModels } from '~/shared/api/utils'
import { DadataAddressModel } from '~/model/Dadata'

export class DadataService {
  private baseURL = '/suggestions/api/4_1/rs/suggest/address'

  private apiClient = axios.create({
    baseURL: this.baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })

  public async getCitiesByRegion(region: string): Promise<{ id: number; name: string }[]> {
    try {
      const response = await this.apiClient.get('', {
        params: {
          query: region,
          from_bound: { value: 'city' },
          to_bound: { value: 'city' }
        }
      })
      if (response.data.suggestions.length === 0) {
        return [
          {
            id: 1,
            name: 'Другой'
          }
        ]
      }
      return response.data.suggestions
        .filter(suggestion => suggestion.value !== region)
        .map((suggestion, index) => ({
          id: index,
          name: suggestion.value.replace(region + ', ', '').trim()
        }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }
  }

  public async searchCities(query: string, region: string): Promise<{ id: number; value: string }[]> {
    try {
      const response = await this.apiClient.get('', {
        params: {
          query,
          from_bound: { value: 'city' },
          to_bound: { value: 'city' },
          region
        }
      })

      return response.data.suggestions
        .filter(suggestion => suggestion.value !== region)
        .map((suggestion, index) => ({
          id: index,
          name: suggestion.value.replace(region + ', ', '').trim()
        }))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }
  }

  public suggestAddress(params: DadataAddressSuggestionParams): Promise<Array<DadataAddressModel>> {
    // Значение количества адресов по умолчанию
    const defaultCount = 10

    const body = { ...params }
    body.count = body.count ?? defaultCount

    return this.apiClient.post('', body).then(toModels(DadataAddressModel))
  }
}
