import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { CareerRoutesDetailVideoItemModel } from '~/model/CareerRoutes/index'

export default class CareerRoutesDetailVideoBlockModel extends BaseModel {
  description: string = ''
  items: Array<CareerRoutesDetailVideoItemModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.items)) {
        this.items = data.items.map(i => new CareerRoutesDetailVideoItemModel(i))
      }
    }
  }
}
