import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class CareerRoutesCardModel extends BaseModel {
  'career_direction': string = ''
  description: string = ''
  id: number = 0
  name: string = ''
  slug: string = ''
  'card_image': string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (data.card_image) {
        this.card_image = new ImageService().cropImage(data.card_image, {})
      }
    }
  }
}
