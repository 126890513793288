/* eslint-disable camelcase */
interface Theme {
  theme_name: string
}
export class CompetitionsItemModel {
  id: number = -1
  infoBlocks?: any[]
  slug: string = ''
  name?: string = ''
  description?: string = ''
  image?: string = ''
  background?: string = ''
  organizer?: string = ''
  place?: string = ''
  format?: string = ''
  start_date?: string = ''
  end_date?: string = ''
  reg_date_start?: string = ''
  reg_date_end?: string = ''
  link?: string = ''
  is_published?: number = 0
  created_at?: string = ''
  updated_at?: string = ''
  deleted_at?: string = ''
  age?: string = ''
  color?: string = ''
  theme?: Theme[] = []
  time: string = ''
  subjects: string[] = []
  tooltip: string = ''
}
