import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CareerRoutesDetailModel } from '~/model/CareerRoutes'
import CareerRoutesCardModel from '~/model/Cards/CareerRoutesCardModel'

@Module({ namespaced: true, stateFactory: true, name: 'careerRoutes' })
export default class extends VuexModule {
  private professionsList: Array<CareerRoutesCardModel> = []
  private professionItem: CareerRoutesDetailModel = new CareerRoutesDetailModel({})

  @Mutation
  private mutationSetProfessionsList(professionsList: CareerRoutesCardModel[]): void {
    this.professionsList = professionsList
  }

  @Mutation
  private mutationSetProfessionItem(professionItem: CareerRoutesDetailModel): void {
    this.professionItem = professionItem
  }

  @Action
  public setProfessionsList(list: CareerRoutesCardModel[]): void {
    this.context.commit('mutationSetProfessionsList', list)
  }

  @Action
  public setProfessionItem(item: CareerRoutesDetailModel): void {
    this.context.commit('mutationSetProfessionItem', item)
  }

  get ProfessionsList(): CareerRoutesCardModel[] {
    return this.professionsList
  }

  get ProfessionItem(): CareerRoutesDetailModel {
    return this.professionItem
  }
}
