import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0d3b5c08&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=0d3b5c08&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d3b5c08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Cookie: require('/usr/src/app/components/Cookie/Cookie.vue').default,Header: require('/usr/src/app/components/Header/Header.vue').default,Footer: require('/usr/src/app/components/Footer/Footer.vue').default,ModalAuth: require('/usr/src/app/components/Modal/ModalAuth.vue').default,RegionLocation: require('/usr/src/app/components/RegionLocation/RegionLocation.vue').default})
