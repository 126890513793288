import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'
import { LandingBannerButtonModel, LandingLogoItemModel } from '~/model/Landings/index'

export default class LandingRectangularBannerBlockModel extends BaseModel {
  name: 'rectangular_banner_block' = 'rectangular_banner_block'
  title: string = ''
  text?: string = ''
  logos?: Array<LandingLogoItemModel> = []
  button: LandingBannerButtonModel = new LandingBannerButtonModel({})
  'background_image': string = ''
  'is_show_feedback_button': boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (Array.isArray(data.logos)) {
        this.logos = data.logos.map(i => new LandingLogoItemModel(i))
      }

      if (data.button) {
        this.button = new LandingBannerButtonModel(data.button)
      }

      if (this.background_image) {
        this.background_image = new ImageService().cropImage(this.background_image, {})
      }

      if (data.is_show_feedback_button) {
        this.is_show_feedback_button = Boolean(Number(data.is_show_feedback_button))
      }
    }
  }
}
