import { Context, Middleware } from '@nuxt/types'
import { RequestException } from '~/types/exceptions'
import { Page } from '~/model/PagesModel'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { UnknownObject } from '~/shared/interfaces'
import { ImageService } from '~/services/Misc/ImageService'

const getSeoMetadata = (context: Context): Promise<Page> => {
  if (!context.route.path || context.route.name?.startsWith('account')) {
    return Promise.resolve(new Page())
  }

  let path = context.route.path === '/' ? 'main' : context.route.fullPath

  if (path.startsWith('/')) {
    path = path.substring(1)
  }

  const url = new URL(`http://localhost/${path}`)
  url.searchParams.set('url', url.pathname.substring(1))

  const params: UnknownObject = {}
  for (const k of url.searchParams.keys()) {
    params[k] = url.searchParams.get(k)
  }

  const token = context.$services.user.getCookieToken()
  if (token) {
    context.$axios.defaults.headers.common['Authorization-SF-Token'] = token
  }

  return context.$services.pagesService.metaDataService.getList(params).then(list => {
    const page = new Page()
    if (list.data.length >= 1 && isUnknownObject(list.data[0])) {
      const data = list.data[0]
      fillOwnProperties(page, data)
      if ('image' in data && data.image !== '') {
        page.image = context.$config.hosts.externalHost + new ImageService().cropImage(data.image, {})
      }
      page.url = context.$config.hosts.externalHost + context.route.fullPath
    }

    return page
  })
}

const getBreadCrumbs = (context: Context) => {
  const path = context.route.path === '/' ? 'main' : 'main/' + context.route.fullPath

  return context.$services.breadcrumbs.getListModel(path)
}

const pageMetadataMiddleware: Middleware = async (context: Context) => {
  const data = await Promise.allSettled([getSeoMetadata(context), getBreadCrumbs(context)])

  if (data[0].status === 'rejected') {
    if (data[0].reason instanceof RequestException && data[0].reason.internalError.status === 404) {
      context.error({ statusCode: 404 })
      return
    }
  } else {
    await context.$tstore.pageMetadata.setSeoMetadata(data[0].value)
  }

  if (data[1].status === 'fulfilled') {
    context.$tstore.pageMetadata.setBreadcrumbs(data[1].value)
  }
}
export default pageMetadataMiddleware
