import { BaseModel } from '~/shared/model'
import { isString, isUnknownObject } from '~/shared/utils/guards'
import { uuid } from '~/shared/utils/helpers'

export default class GeoPosition extends BaseModel {
  public readonly id: string = uuid()
  public name: string = ''
  public postalCode: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      if (isString(data.value)) {
        this.name = data.value
      }

      if (isUnknownObject(data.data) && isString(data.data.postal_code) && data.data.postal_code) {
        this.postalCode = data.data.postal_code
      }
    }
  }
}
