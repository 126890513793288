import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class NewsRecommendationCardModel extends BaseModel {
  id: string = ''
  name: string = ''
  slug: string = ''
  views: string = ''
  date: string = ''
  time: string = ''
  image: string = ''
  'updated_at': string = ''
  'is_horizontal': boolean = false

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.updated_at) {
        this.date = new Date(this.updated_at.replace(' ', 'T')).toLocaleDateString('fr-CH')
      }
    }

    if (this.image) {
      this.image = new ImageService().cropImage(this.image, {})
    }
  }
}
