import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel } from '~/shared/model'
import { toDateFormat } from '~/shared/utils/date'

export default class VacancyModel extends BaseModel {
  public id: number = 0
  public externalId: number = 0
  public innerId: number = 0
  public name: string = ''
  public organizationSlug: string = ''
  public positionValue: string = ''
  public salary: number = 0
  public organizationValue: string = ''
  public regionValue: string = ''
  public importOrgName: string = ''
  public responsibilities: string = ''
  public requirements: string = ''
  public housing: string = ''
  public housingValue: string = ''
  public updated: string = ''
  public employmentValue: string = ''
  public path: string = ''
  public isCanonical: boolean = false
  public foreignWorker: boolean = false
  public isSimpleFeedback: boolean = false
  public isDeleted: boolean = false
  public deletedAt: string = ''
  public experienceValue: string = ''
  public workplaceValue: string = ''
  public educationValue: string = ''
  public conditions: string = ''
  public schedule: string = ''
  public importSourceName: string | null = null
  public importVacancyUrl: string | null = null

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }

    if (this.deletedAt) {
      this.deletedAt = toDateFormat(this.deletedAt)
    }
  }
}
