
import { defineComponent, useContext, useRouter } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import BreadCrumbs from '~/components/UI/BreadCrumbs.vue'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

export default defineComponent({
  components: { SvoiButton, BreadCrumbs, AppLazyImage },
  setup() {
    const ctx = useContext()
    const router = useRouter()

    const goToVacanciesPage = () => {
      const region = ctx.$services.geolocation.getCookieRegion()

      const path =
        region && region.slug !== '' && region.slug !== 'russia' ? `/vacancies/region-${region.slug}` : '/vacancies'
      router.push(path)
    }

    return {
      goToVacanciesPage
    }
  },
  head() {
    return {
      title: 'Вакансия не найдена - Я в агро',
      description: '',
      h1: '',
      keywords: ''
    }
  }
})
