import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { ImageService } from '~/services/Misc/ImageService'

export default class CareerRoutesDetailAboutVacancyBlockModel extends BaseModel {
  profession: string = ''
  link: string = ''
  name: string = ''
  slug: string = ''
  image: string = ''
  url: string = ''
  'vacancies_count': number = 0
  salary: number = 0
  'internships_count': number = 0
  cities: number = 0
  'regions_total': number = 0
  'workplaces_total': number = 0
  'vacancies_max_amount': number = 0

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (this.image) {
        this.image = new ImageService().cropImage(this.image, {})
      }
    }
  }
}
